<!-- 学员编辑 -->
<template>
    <div class="EditPupil">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
         <el-form ref="form" :model="form" :rules="rules"  class="form" inline label-width="160px">
            <!-- <el-form-item label="序号">
                 <el-input v-model="form.number" placeholder="请输入"></el-input>
            </el-form-item> -->
            <el-form-item label="选择课表">
                 <el-select v-model="form.timeTable" filterable placeholder="请输入并选择课表"
                  :clearable="true">
                     <el-option
                       v-for="item in form.timeTableOpt"
                       :key="item.id"
                       :label="item.class_name"
                       :value="item.id">
                     </el-option>
                   </el-select>
            </el-form-item>
            <el-form-item label="签到时间">
                 <el-time-picker
                 value-format="HH:mm"
                    format="HH:mm"
                    v-model="form.signInTime"
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                    placeholder="选择时间">
                  </el-time-picker>
            </el-form-item>
            <el-form-item label="签到状态">
                 <el-select v-model="form.signInState"  placeholder="请选择签到状态"
                  :clearable="true">
                     <el-option
                       v-for="item in form.signInStateOpt"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
                     </el-option>
                   </el-select>
            </el-form-item>
            <el-form-item label="学生姓名" prop="stuName">
                 <el-select v-model="form.stuName" filterable remote placeholder="请输入并选择学员姓名" :clearable="true"
                     :remote-method="students" @change="studentChange">
                     <el-option v-for="item in form.stuNameOpt" :key="item.id" :label="item.username"
                         :value="item.id">
                     </el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="学生卡号">
                 <el-input v-model="form.stuCard" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="上课类">
                 <el-select v-model="form.attendType"   placeholder="请输入上课类"
                  :clearable="true">
                     <el-option
                       v-for="item in form.attendTypeOpt"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
                     </el-option>
                   </el-select>
            </el-form-item>
            <el-form-item label="学生描述">
                 <el-input v-model="form.describe" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="内部备注">
                 <el-input v-model="form.remarks" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="经办人员">
                 <el-select v-model="form.handle"  placeholder="请输入并选择经办人员"
                  :clearable="true">
                     <el-option
                       v-for="item in form.handleOpt"
                       :key="item.id"
                       :label="item.fullname"
                       :value="item.id">
                     </el-option>
                   </el-select>
            </el-form-item>
         </el-form>
         <el-divider></el-divider>
         <!-- 底部 提交与取消 -->
         <div slot="footer" class="dialog-footer">
             <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
             <el-button class="btn" @click="resetForm('form')">取 消</el-button>
         </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   number:"",//序号
                   timeTable:"",//选择课表
                   timeTableOpt:[],//课表选项
                   signInTime:"",//签到时间
                   signInState:"",//签到状态
                   signInStateOpt:[
                       {value:1,label:'签到'},
                       {value:2,label:'请假'},
                       {value:3,label:'未到'},
                       {value:4,label:'休学'}
                   ],//签到状态选项
                   stuName:"",//学生姓名
                   stuNameOpt:[],//学生姓名选项
                   stuCard:"",//学生卡号
                   attendType:"",//上课类
                   attendTypeOpt:[
                       {value:1,label:'常规'},
                       {value:2,label:'试学'},
                       {value:3,label:'补课'},
                   ],//上课类选项
                   describe:"",//学生描述
                   remarks:"",//内部备注
                   handle:"",//经办人员
                   handleOpt:[]//经办人员选项
                },
                rules:{
                    stuName:[
                        { required: true, message: '学生姓名不能为空', trigger: 'change' },
                    ],
                },
                bool:false
            }
        },
        created() {
            // 选择课表数据
            this.$request({
                url:'/api/class_/scheduleList',
                method:'POST',
                data:{
                    class_id:this.$route.query.class_id,
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'排课表数据')
                    this.form.timeTableOpt=res.data.list
                }
            })
            // 经办人员
            this.$request({
                url:'/api/humanresources/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    // console.log(res,'经办人员')
                    this.form.handleOpt=res.data.list
                }
            })
            
            // 初次渲染
            this.$request({
                url:'/api/classsign/studentSignDetail',
                method:'POST',
                data:{
                    id:this.$route.query.id
                }
            }).then(res=>{
                if(res.code==1){
                    // console.log(res)
                    let data = res.data
                    let form = this.form
                    form.timeTable=data.schedule_id//选择课表
                    form.signInTime=data.sign_time//签到时间
                    form.signInState=data.sign_status//签到状态
                    form.stuName=data.student_id//学生姓名
                    form.stuCard=data.student_card//学生卡号
                    form.attendType=data.sign_type//上课类
                    form.describe=data.student_describe//学生描述
                    form.remarks=data.remark//内部备注
                    form.handle=data.add_id//经办人员
                }
            })
            this.students()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            //学生数据
            students(e) {
                // console.log(e);
                this.$request({
                    url: "/api/student/list",
                    method: "POST",
                    data: {
                        page: 1,
                        limit: 100,
                        key: e
                    }
                }).then(res => {
                    // console.log("学生数据", res.data.list)
                    this.form.stuNameOpt = res.data.list
                })
            },
            studentChange(e){//学生数据
                console.log(e)
                let result = this.form.stuNameOpt.find(item => item.id == e)
                this.form.stuCard=result.student_card
                console.log(result)
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url:'/api/classsign/studentSignEdit',
                            method:'POST',
                            data:{
                                id:this.$route.query.id,
                                schedule_id:form.timeTable,//排课表id
                                student_id:form.stuName,//学生id
                                student_card:form.stuCard,//学生卡号
                                student_describe:form.describe,//学生描述
                                sign_type:form.attendType,//上课类
                                sign_status:form.signInState,//签到状态
                                remark:form.remarks,//内部说明
                                add_id:form.handle,//经办人id
                                sign_time:form.signInTime//签到时间
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                    message: '修改列表成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .EditPupil{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>